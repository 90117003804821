<template>
    <div v-if="dailyReports && person">
        <div class="text-right mb-2">
            <button class="btn btn-info" @click="printReport">印刷</button>
        </div>
        <CCard id="printable">
            <CCardBody>
                <div class="container-fluid">
                    <div class="row pb-5">
                        <div class="col-12 mx-auto">
                            <h3 class="mx-auto text-center">{{ person.attributes.name ? person.attributes.name : '' }}さんの心と体のメーター日記</h3>
                        </div>
                    </div>
                    <div class="row mx-auto pb-5" style="width: 90%">
                        <div class="col-6 pb-5" v-for="(dailyReport,index) in dailyReports" :key="index">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12 mx-auto pb-3 text-center">
                                            {{
                                                $moment(dailyReport.attributes.register_date).format('YYYY年MM月DD日') + weekStr($moment(dailyReport.attributes.register_date).format('e')) + '  ' +
                                                dailyReport.attributes.weather + '  ' + round(dailyReport.attributes.temperature, 10) + '℃ / ' +
                                                dailyReport.attributes.humidity + '%'
                                            }}
                                        </div>
                                    </div>
                                    <div class="row pb-4">
                                        <div class="col-12 pb-2 font-weight-bold font-lg">
                                            今日の調子はどう？
                                        </div>
                                        <div class="col-12 pb-1">
                                            <div class="progress background font-weight-bold large-bar"
                                                 style="background-color: #DDDDDD">
                                                <div class="progress-bar progress-bar-striped bg-warning"
                                                     role="progressbar"
                                                     :style="'width:' + dailyReport.attributes.condition_point + '%'"
                                                     aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                    {{ dailyReport.attributes.condition_point }}点
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 pb-3 font-weight-bold font-lg">
                                            調子のなかみを見てみる
                                        </div>
                                        <div class="col-4">
                                            <p class="my-0">①からだ</p>
                                        </div>
                                        <div class="col-8">
                                            <div class="progress background font-weight-bold middle-bar"
                                                 style="background-color: #DDDDDD">
                                                <div class="progress-bar progress-bar-striped" role="progressbar"
                                                     :style="'width:' + dailyReport.attributes.body_point + '%'"
                                                     aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                                                    {{ dailyReport.attributes.body_point }}点
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4"></div>
                                        <div class="col-8">
                                            <p class="text-break new-line">
                                                {{ dailyReport.attributes.body }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <p class="my-0">②きもち</p>
                                        </div>
                                        <div class="col-8">
                                            <div class="progress background font-weight-bold middle-bar"
                                                 style="background-color: #DDDDDD; height: 20px; font-size: large">
                                                <div class="progress-bar progress-bar-striped bg-info"
                                                     role="progressbar"
                                                     :style="'width:' + dailyReport.attributes.feeling_point + '%'"
                                                     aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                                                    {{ dailyReport.attributes.feeling_point }}点
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4"></div>
                                        <div class="col-8">
                                            <p class="text-break new-line">
                                                {{ dailyReport.attributes.feeling }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <p class="my-0">③イライラ</p>
                                        </div>
                                        <div class="col-8">
                                            <div class="progress background font-weight-bold middle-bar"
                                                 style="background-color: #DDDDDD; height: 20px; font-size: large">
                                                <div class="progress-bar progress-bar-striped bg-danger"
                                                     role="progressbar"
                                                     :style="'width:' + dailyReport.attributes.frustration_point + '%'"
                                                     aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                                    {{ dailyReport.attributes.frustration_point }}点
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4"></div>
                                        <div class="col-8">
                                            <p class="text-break new-line">
                                                {{ dailyReport.attributes.frustration }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <p class="my-0">④食事</p>
                                        </div>
                                        <div class="col-8">
                                            <div class="progress background font-weight-bold middle-bar"
                                                 style="background-color: #DDDDDD; height: 20px; font-size: large">
                                                <div class="progress-bar progress-bar-striped"
                                                     style="background-color: #770000"
                                                     role="progressbar"
                                                     :style="'width:' + dailyReport.attributes.diet_point + '%'"
                                                     aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    {{ dailyReport.attributes.diet_point }}点
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4"></div>
                                        <div class="col-8">
                                            <p class="text-break new-line">
                                                {{ dailyReport.attributes.diet }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <p class="my-0">⑤睡眠</p>
                                        </div>
                                        <div class="col-8">
                                            <div class="progress background font-weight-bold middle-bar"
                                                 style="background-color: #DDDDDD; height: 20px; font-size: large">
                                                <div class="progress-bar progress-bar-striped bg-success"
                                                     role="progressbar"
                                                     :style="'width:' + dailyReport.attributes.sleep_point + '%'"
                                                     aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    {{ dailyReport.attributes.sleep_point }}点
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4"></div>
                                        <div class="col-8">
                                            <p class="text-break new-line">
                                                {{ dailyReport.attributes.sleep }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import VTextArea from "@/components/Forms/VTextArea";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "DisplayMedicalCooperationNursingReportChildren",
    components: {VTextArea},
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            dailyReports: null,
            person: null
        }
    },
    created: function () {
        this.loadDailyReportData();
        this.loadPersonData();
    },
    methods: {
        loadDailyReportData: function () {
            window.axios.get(this.routes.childDisplayMedicalCooperation, {
                params: {
                    person_id: this.$route.params.id,
                    register_date: this.$route.query.date
                }
            })
                .then((response) => {
                    this.dailyReports = response.data.data;
                })
                .catch((error) => {

                })
        },
        loadPersonData: function () {
            window.axios.get(this.routes.person(this.$route.params.id))
                .then((response) => {
                    this.person = response.data.data;
                })
                .catch((error) => {

                })
        },
        floor: function (data, digit) {
            if (data === 0) return '0';
            return Math.floor(data * digit) / digit;
        },
        round: function (data, digit) {
            if (data === 0) return '0';
            return Math.round(data * digit) / digit;
        },
        printReport: function () {
            window.document.body.innerHTML = document.getElementById('printable').innerHTML;
            window.print();
            this.$router.go({path: this.$router.currentRoute.path, force: true});
        }
    },
    computed: {
        weekStr: function () {
            return function (week) {
                switch (parseInt(week)) {
                    case 0:
                        return '(日)'
                    case 1:
                        return '(月)'
                    case 2:
                        return '(火)'
                    case 3:
                        return '(水)'
                    case 4:
                        return '(木)'
                    case 5:
                        return '(金)'
                    case 6:
                        return '(土)'
                    default:
                        return '-'
                }
            }
        },
    }
}
</script>

<style src="./DisplayMedicalCooperationNursingReportChildren.scss" lang="scss" scoped/>
